@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
/* @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css'); */


.gallery {
  width: 80%;
  margin: 0 auto;
}
.gallery__item img {
  width: 100%;
}
