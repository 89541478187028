
  .portfolio{
    width: 100%;
    padding: 30px 8%;
    background-color: var(--body-clr);
  }
  .portfolio .section-head{
    max-width: 700px;
    margin: 0px auto 25px;
    text-align: center;
  }
  .section-head h1{
    position: relative;
    font-size: 32px;
    margin: 10px 0px 30px;
    color: var(--primary-clr);
  }
  
  div.gallery_1{
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
  }
  div.gallery_1 .item{
    position: relative;
    margin: 4px;
    width: calc(33.33% - 8px);
    overflow: hidden;
    cursor: pointer;
  }
  .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: 0.3s;
  }
  div.gallery_1 .item:hover img{
    transform: scale(1.15);
  }
  
  .item .overlay a:hover{
    background-color: var(--body-clr);
    color: var(--primary-clr);
  }
  
  
  @media(max-width: 1024px){
    div.gallery_1 .item{
      width: calc(50% - 8px);
    }
  }
  
  @media(max-width: 600px){
    div.gallery_1 .item{
      width: 100%;
      margin: 4px 0px;
    }
  }